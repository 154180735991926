import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'default-passive-events'
// 引入element组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/global.css'

import './assets/icon/font_4m3g21dnrvg/iconfont.css'


import * as WPS from '../utils/web-office-sdk-solution-v2.0.6.umd'

import md5 from 'js-md5'

import * as CrytoJs from 'crypto-js'


// 弹出框的时候滚动条隐藏和出现导致页面抖动问题
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI)

Vue.prototype.wps = WPS
Vue.prototype.$md5 = md5


// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 导入请求
import API from "../utils/index";
// 挂载到Vue
Vue.use(API);

// 接口配置
import axios from "axios";
import global_ from '../config/env'
Vue.prototype.GLOBAL = global_;
//配置请求的根路径
axios.defaults.baseURL = global_;
Vue.prototype.$http = axios


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
