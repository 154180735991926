// 模块接口文件，暴漏给外部模块使用

// 导入核心模块
import {exportDetail, request} from "./core";
// 导入请求方式
import METHODS from "./methods";
// 导入请求接口
import URL from "./url";

const API = {
    // 登录接口
    login(data) {
        return request(METHODS.POST, URL.login, data);
    },
    // 校验找回密码验证码
    verifyForgetCode(data) {
        return request(METHODS.POST, URL.verifyForgetCode, data);
    },
    // 找回密码修改密码
    retrievePassword(data) {
        return request(METHODS.POST, URL.retrievePassword, data);
    },
    //获取前端菜单路由接口
    getRouters(data) {
        return request(METHODS.GET, URL.getRouters, {params: data});
    },
    // 修改个人信息
    updateProfile(data) {
        return request(METHODS.POST, URL.updateProfile, data);
    },
    // 修改密码
    conferenceUpdatePass(data) {
        return request(METHODS.POST, URL.conferenceUpdatePass, data);
    },
    // 退出登录
    conferenceLogout() {
        return request(METHODS.GET, URL.conferenceLogout);
    },
    // 登录发送手机验证码
    sms(data) {
        return request(METHODS.POST, URL.sms, data);
    },
    // 登录发送手机验证码
    forgetSms(data) {
        return request(METHODS.POST, URL.forgetSms, data);
    },

    // 注册发送手机验证码
    registerSms(data) {
        return request(METHODS.POST, URL.registerSms, data);
    },
    // 注册信息提交
    registerSubmit(data) {
        return request(METHODS.POST, URL.registerSubmit, data);
    },

    // 查询用户会场角色
    findVenueRole(data) {
        return request(METHODS.POST, URL.findVenueRole, data);
    },

    // 参会人员管理
    //会议列表（带分页）
    pageList(data) {
        return request(METHODS.POST, URL.pageList, data);
    },
    //新增或编辑（参会人员）
    addAttenMans(data) {
        return request(METHODS.POST, URL.addAttenMans, data);
    },
    //新增 编辑 参会人员住宿 车辆信息
    addDetailMans(data) {
        return request(METHODS.POST, URL.addDetailMans, data);
    },
    //根据参会人id查询参会人详情
    findAttenDetail(data) {
        return request(METHODS.POST, URL.findAttenDetail, data);
    },
    //导出参会人员信息
    exportAttenDetail(data, name) {
        return exportDetail(METHODS.GET, URL.exportAttenDetail, data, name);
    },
    //导出参会人模板
    exportTemplate(data, name) {
        return exportDetail(METHODS.GET, URL.exportTemplate, data, name);
    },
    //导出线下签到表
    exportSignInDetail(data, name) {
        return exportDetail(METHODS.GET, URL.exportSignInDetail, data, name);
    },
    //删除参会人员
    deleAttenMans(data) {
        return request(METHODS.POST, URL.deleAttenMans, data);
    },
    //查找会议
    findAttenMans(data) {
        return request(METHODS.POST, URL.findAttenMans, data);
    },
    // 状态修改
    publiAttenMans(data) {
        return request(METHODS.POST, URL.publiAttenMans, data);
    },
    //根据id查询参会人员
    findById(data) {
        return request(METHODS.POST, URL.findById, data);
    },
    // 编辑参会人员
    updaAttenMans(data) {
        return request(METHODS.POST, URL.updaAttenMans, data);
    },

    // 查询对接人列表
    dockingPersonList(data) {
        return request(METHODS.POST, URL.dockingPersonList, data);
    },
    // 上传模板
    importParticipateUser(data) {
        return request(METHODS.POST, URL.importParticipateUser, data);
    },

    // 发送会议邀请
    sendInviteSMS(data) {
        return request(METHODS.POST, URL.sendInviteSMS, data);
    },

    // 发送活动邀请
    sendActivitySMS(data) {
        return request(METHODS.POST, URL.sendActivitySMS, data);
    },

    // 发送新闻快讯
    sendNewsSMS(data) {
        return request(METHODS.POST, URL.sendNewsSMS, data);
    },

    // 会议管理
    //添加/修改（会议）
    saveConference(data) {
        return request(METHODS.POST, URL.saveConference, data);
    },
    // 删除会议
    delconfer(data) {
        return request(METHODS.POST, URL.delconfer, data);
    },
    // 查找会议
    findconfer(data) {
        return request(METHODS.POST, URL.findconfer, data);
    },
    //会议列表（带分页）
    conferList(data) {
        return request(METHODS.POST, URL.conferList, data);
    },
    // 修改状态
    publiConfer(data) {
        return request(METHODS.POST, URL.publiConfer, data);
    },
    //上传会议海报
    uploadPosters(data) {
        return request(METHODS.POST, URL.uploadPosters, data);
    },
    // 编辑会议
    editConference(data) {
        return request(METHODS.POST, URL.editConference, data);
    },

    // 会议列表
    meetingPageList(data) {
        return request(METHODS.POST, URL.meetingPageList, data);
    },
    meetingList(data) {
        return request(METHODS.POST, URL.meetingList, data);
    },

    // 分会场列表 下拉框
    conferenceList(data) {
        return request(METHODS.POST, URL.conferenceList, data);
    },
    //添加/修改（会议）
    saveMeeting(data) {
        return request(METHODS.POST, URL.saveMeeting, data);
    },
    //添加/修改（会议）
    editMeeting(data) {
        return request(METHODS.POST, URL.editMeeting, data);
    },
    //删除会议
    deleteMeet(data) {
        return request(METHODS.POST, URL.deleteMeet, data);
    },


    // 工作人员管理
    // 工作人员信息列表
    staffPageList(data) {
        return request(METHODS.GET, URL.staffPageList, {params: data});
    },
    // 修改工作人员
    updateStaff(data) {
        return request(METHODS.POST, URL.updateStaff, data);
    },
    // 删除工作人员
    deleteStaff(data) {
        return request(METHODS.POST, URL.deleteStaff, data);
    },
    // 新增工作人员
    addStaff(data) {
        return request(METHODS.POST, URL.addStaff, data);
    },
    // 根据手机号查询用户信息
    getStaffByPhone(data) {
        return request(METHODS.POST, URL.getStaffByPhone, data);
    },

    // 任务管理
    // 获取任务列表
    getTaskList(data) {
        return request(METHODS.POST, URL.getTaskList, data);
    },
    // 新增任务与工作人员关系
    insertUserAndMenu(data) {
        return request(METHODS.POST, URL.insertUserAndMenu, data);
    },
    // 任务与工作人员关系
    taskLoad(data) {
        return request(METHODS.POST, URL.taskLoad, data);
    },
    // 查询任务类型
    getTaskTypeList(data) {
        return request(METHODS.POST, URL.getTaskTypeList, data);
    },
    //查询任务下人员进度
    findUserPlanByTid(data) {
        return request(METHODS.POST, URL.findUserPlanByTid, data);
    },

    // 待办管理
    // 代办列表
    commissionList(data) {
        return request(METHODS.POST, URL.commissionList, data);
    },
    // 代办状态修改
    updateStatus(data) {
        return request(METHODS.POST, URL.updateStatus, data);
    },

    // 会议议程管理列表
    fileMsgList(data) {
        return request(METHODS.GET, URL.fileMsgList, {params: data});
    },
    // 新建wps文件
    createTemplate(data) {
        return request(METHODS.GET, URL.createTemplate, {params: data});
    },
    // 删除文件
    deleteConferenceFile(data) {
        return request(METHODS.GET, URL.deleteConferenceFile, {params: data});
    },
    // 上传文件
    uploadConferenceFile(data) {
        return request(METHODS.POST, URL.uploadConferenceFile, data);
    },
    //下载文件
    downloadConferenceFile(data, name) {
        return exportDetail(METHODS.GET, URL.downloadConferenceFile, data, name);
    },

    //模版管理
    //模板列表
    fileTemplateList(data) {
        return request(METHODS.POST, URL.fileTemplateList, data);
    },
    // 上传模板
    uploadTemplate(data) {
        return request(METHODS.POST, URL.uploadTemplate, data);
    },
    // 删除模板
    deleteTemplateFile(data) {
        return request(METHODS.POST, URL.deleteTemplateFile, data);
    },
    // 下载模板
    downloadTemplateFile(data, name) {
        return exportDetail(METHODS.GET, URL.downloadTemplateFile, data, name);
    },

    // 签到管理
    signInPageList(data) {
        return request(METHODS.POST, URL.signInPageList, data);
    },

    downloadQrCode(data, name) {
        return exportDetail(METHODS.GET, URL.downloadQrCode, data, name);
    },

    // 新闻
    newsTemplateList(data, name) {
        return request(METHODS.POST, URL.newsTemplateList, data, name);
    },

    // 注册审核列表
    registerAuditList(data) {
        return request(METHODS.POST, URL.registerAuditList, data);
    },
    updateAuditStatus(data) {
        return request(METHODS.POST, URL.updateAuditStatus, data);
    },

    // 审核参会人报名
    updateAttendAuditStatus(data) {
        return request(METHODS.POST, URL.updateAttendAuditStatus, data);
    },

    // 参会人报名审核列表
    attendRegisterAuditList(data) {
        return request(METHODS.POST, URL.attendRegisterAuditList, data);
    },

    // 参会人角色列表
    attendRoleList(data) {
        return request(METHODS.POST, URL.attendRoleList, data);
    },
    // 删除参会人角色
    deleteAttendRole(data) {
        return request(METHODS.POST, URL.deleteAttendRole, data);
    },
    // 新增参会人角色
    addAttendRole(data) {
        return request(METHODS.POST, URL.addAttendRole, data);
    },
    // 参会人角色列表 不分页
    attendRoleListAll(data) {
        return request(METHODS.POST, URL.attendRoleListAll, data);
    }
    ,
    // 参会人员端模板
    modulePageList(data) {
        return request(METHODS.POST, URL.modulePageList, data);
    }
    ,
    // 参会人员端模板
    saveModule(data) {
        return request(METHODS.POST, URL.saveModule, data);
    }
    ,
    // 参会人员端模板编辑
    editModule(data) {
        return request(METHODS.POST, URL.editModule, data);
    }
    ,
    // 删除模板
    deleteModule(data) {
        return request(METHODS.POST, URL.deleteModule, data);
    },

    // 公告管理
    // 上传公告文件
    uploadNoticeFile(data){
        return request(METHODS.POST, URL.uploadNoticeFile, data);
    },// 上传公告文件
    noticeManageList(data){
        return request(METHODS.POST, URL.noticeManageList, data);
    },// 新增公告
    saveNoticeManage(data){
        return request(METHODS.POST, URL.saveNoticeManage, data);
    },// 修改公告
    updateNoticeManage(data){
        return request(METHODS.POST, URL.updateNoticeManage, data);
    },// 删除公告
    deleteNoticeManage(data){
        return request(METHODS.POST, URL.deleteNoticeManage, data);
    },// 查询单个公告
    findNoticeManageById(data){
        return request(METHODS.POST, URL.findNoticeManageById, data);
    },// 查找分会场下所有参会人员
    findParticipateUsersByCid(data){
        return request(METHODS.POST, URL.findParticipateUsersByCid, data);
    },
    //手动发送参会人员公告
    pushNotice(data){
        return request(METHODS.POST, URL.pushNotice, data);
    },// 查询短信发送列表
    sendSMSList(data){
        return request(METHODS.POST, URL.sendSMSList, data);
    },// 短信发送
    sendSMS(data){
        return request(METHODS.POST, URL.sendSMS, data);
    }

}
export default {
    // 将对象注入到Vue全局
    install(Vue) {
        Vue.prototype.$axios = API;
    }
};

