export default {
    login: "/auth/conferenceLogin", //登录
    verifyForgetCode: "/auth/verifyCode", //校验找回密码验证码
    retrievePassword: "/auth/retrievePassword", //找回密码修改密码
    getRouters:"/api/menus/getRouters",//获取前端菜单路由接口
    updateProfile:"/auth/updateProfile",//修改个人信息
    conferenceUpdatePass:"/api/users/conferenceUpdatePass",//修改密码
    conferenceLogout:"/auth/conferenceLogout",//退出登录
    sms:"/auth/sms",//登录发送手机验证码
    forgetSms:"/auth/sendSmsPassword",//找回密码发送验证码
    registerSms:"/auth/sendLoginSmsPassword",//注册发送验证码
    registerSubmit:"/api/auditUser/saveAuditUser",//保存注册信息
    findVenueRole:"/api/users/findRoleByCidUid", // 查询用户在分会场中的角色

    //
    fileMsgList:"/api/wpsFileMsg/fileMsgList",//会议议程管理列表
    createTemplate:"/api/wpsFileMsg/createTemplate",//新建wps文件
    deleteConferenceFile:"/api/wpsFileMsg/deleteConferenceFile",//删除文件
    uploadConferenceFile:"/api/wpsFileMsg/uploadConferenceFile",//上传文件
    downloadConferenceFile:"/api/wpsFileMsg/downloadConferenceFile",//下载文件

    // 模版管理
    fileTemplateList:"/api/wpsFileTemplate/fileTemplateList",//模板列表
    uploadTemplate:"/api/wpsFileTemplate/uploadTemplate",//上传模板
    deleteTemplateFile:"/api/wpsFileTemplate/deleteTemplateFile",//删除模板
    downloadTemplateFile:"/api/wpsFileTemplate/downloadTemplateFile",//下载模板

    //参会人员管理
    pageList:"/api/attendeesManage/pageList",//会议列表（带分页）
    addAttenMans:"/api/attendeesManage/save",//新增或编辑（参会人员）
    deleAttenMans:"/api/attendeesManage/deleteById",//删除参会人员
    findAttenMans:"/api/attendeesManage/findById",//查找会议
    publiAttenMans:"/api/attendeesManage/publish",//状态修改
    findById:"/api/attendeesManage/findById",//根据id查询参会人员
    updaAttenMans:"/api/attendeesManage/update",//编辑参会人员
    dockingPersonList:"/api/staffManage/findDockingPerson",//查询对接人列表
    findParticipateUsersByCid:"/api/attendeesManage/findParticipateUsersByCid",//查找分会场下所有参会人员

    // 参会人住宿 车辆
    addDetailMans:"/api/participateUserDetail/saveParticipateUserDetail",//保存 编辑 参会人住宿 车辆信息
    findAttenDetail:"/api/participateUserDetail/findParticipateUserByPidAndCid",
    exportAttenDetail:"/api/attendeesManage/exportParticipateUser",
    exportSignInDetail:"/api/attendeesManage/exportSignInWord",
    exportTemplate:"/api/attendeesManage/downloadTemplate",
    importParticipateUser:"/api/attendeesManage/importParticipateUser",
    sendInviteSMS:"/api/attendeesManage/sendSMS",
    sendActivitySMS:"/api/attendeesManage/sendActivitySMS",
    sendNewsSMS:"/api/attendeesManage/sendNewsSMS",

    // 会议管理
    meetingPageList:"/api/meeting/meetingPageList",//会议列表
    meetingList:"/api/meeting/findByMeetingList",//会议列表
    saveMeeting:"/api/meeting/saveMeeting",//新增会议
    editMeeting:"/api/meeting/editMeeting",//编辑会议
    deleteMeet:"/api/meeting/deleteById",//删除会议

    //会场管理
    saveConference:"/api/conferManage/saveConference",//添加/修改（会议）
    delconfer:"/api/conferManage/deleteById",//删除会议
    findconfer:"api/conferManage/findById",//查找会议
    conferList:"/api/conferManage/pageList",//会议列表（带分页）
    conferenceList:"/api/conferManage/conferenceList",//会场列表
    publiConfer:"api/conferManage/publish",//修改状态
    uploadPosters:"/api/conferManage/uploadPosters",//上传会议海报
    editConference:"/api/conferManage/editConference",//编辑会议


    // 工作人员管理
    staffPageList:"/api/staffManage/staffPageList",//工作人员信息列表
    updateStaff:"/api/staffManage/updateStaff",//修改工作人员
    deleteStaff:"/api/staffManage/deleteStaff",//删除工作人员
    addStaff:"/api/staffManage/addStaff",//新增工作人员
    getStaffByPhone:"/api/staffManage/getStaffByPhone",//根据手机号查询用户信息

    // 任务管理
    getTaskList:"/api/taskManage/getTaskList",//获取任务列表
    insertUserAndMenu:"/api/taskManage/insertUserAndMenu",//新增任务与工作人员关系
    taskLoad:"/api/taskManage/load",//任务与工作人员关系
    getTaskTypeList:"/api/taskManage/getTaskTypeList",//查询任务类型
    findUserPlanByTid:"/api/taskManage/findUserPlanByTid",//查询任务下人员进度


    // 待办管理
    commissionList:"/api/commission/commissionList",//代办列表
    updateStatus:"/api/commission/updateStatus",//代办状态修改

    // 签到管理
    signInPageList:"/api/signInUser/signInPageList",

    downloadQrCode:"/api/conferManage/downloadQrCode",


    // 新闻
    newsTemplateList:"/api/newsBulletin/newsBulletinList",


    // 注册审核列表
    registerAuditList:"/api/auditUser/auditUserList",
    updateAuditStatus:"/api/auditUser/auditUser",
    attendRoleList:"/api/participateUserRole/userRolePage",
    deleteAttendRole:"/api/participateUserRole/deleteUserRole",
    addAttendRole:"/api/participateUserRole/saveUserRole",
    attendRoleListAll:"/api/participateUserRole/userRoleList",

    // 参会人报名审核
    attendRegisterAuditList:"/api/auditParticipateUser/auditPageList",
    updateAttendAuditStatus:"/api/auditParticipateUser/auditParticipateUser",

    // 参会人端模板
    modulePageList:"/api/menuManage/menuPageList",
    saveModule:"/api/menuManage/saveMenu",
    editModule:"/api/menuManage/editMenu",
    deleteModule:"/api/menuManage/deleteMenuById",


    // 公告管理
    uploadNoticeFile:"/api/noticeManage/uploadNoticeFile",//上传文件
    noticeManageList:"/api/noticeManage/noticeManageList",//上传文件
    saveNoticeManage:"/api/noticeManage/saveNoticeManage",//新增公告
    updateNoticeManage:"/api/noticeManage/updateNoticeManage",//修改公告
    deleteNoticeManage:"/api/noticeManage/deleteNoticeManage",//删除公告
    findNoticeManageById:"/api/noticeManage/findNoticeManageById",//查询单个公告

    pushNotice:"/api/noticeManage/sendNotice",//手动发送参会人员公告

    //公告通知人员控制器
    sendSMSList:"/api/noticeManageUser/sendSMSList",//短信发送列表
    sendSMS:"/api/noticeManageUser/sendSMS",//短信发送













};

