// 核心代码模块

// 引入axios
import axios from "axios";
// 导入请求方式
import METHODS from "./methods";

// 定义基础路径
import BASE_URL from "../config/env.js";
const BASEURL = BASE_URL;
// console.log(BASE_URL)

// 创建 axios 配置实例
const service = axios.create({
    baseURL: BASEURL, //  默认会拼接到请求路径前面
    // timeout: 6000, //  请求超时
    // timeout: 900000, //  请求超时
});

//请求拦截
service.interceptors.request.use(request => {
    var token = window.sessionStorage.getItem("token");
    // console.log("请求发出前", request)
    if (request.url == '/auth/conferenceLogin' || request.url == '/auth/sms') { //登录接口
        return request
    } else {
        if (token != undefined) {
            request.headers['Authorization'] =  token
            request.headers['content-type'] = "application/x-www-form-urlencoded"
        }
        return request //最后要返回
    }

}, error => {
    console.log("请求拒绝发出")
    Promise.reject(error) //有错误，则调用catch方法
})

// 请求方法
export function request(method, url, params) {
    switch (method) {
        case METHODS.GET:
            return GET(url, params);

        case METHODS.POST:
            return POST(url, params);

        case METHODS.DEL:
            return DELETE(url, params);
    }
}

export function exportDetail(method, url,data,name) {
    let requestUrl = '';
    if(data){
        requestUrl = BASEURL+url+'?'+data
    }else {
        requestUrl = BASEURL+url
    }
    axios({
        method: method,
        url: requestUrl,
        // 二进制流文件，一定要设置成blob，默认是json
        headers: {
            'Authorization':window.sessionStorage.getItem("token"),
            'content-type':'application/x-www-form-urlencoded'
        },
        responseType: 'blob'
    }).then(res => {
        console.log(res)
        const link = document.createElement('a')
        const blob = new Blob([res.data], { type: res.data.type })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `${name}`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    })
}

// 请求方式
function GET(url, params) {
    return service.get(url, params);
}

function POST(url, params) {
    return service.post(url, params);
}

function DELETE(url, params) {
    return service.delete(url, params);
}



