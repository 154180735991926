// 'use strict';
// /*
//  * 配置编译环境和线上环境之间的切换
//  * BASE_URL: 域名地址
//  */
let BASE_URL = ''

if (process.env.NODE_ENV === 'development') {
    // 测试地址
    // BASE_URL = 'http://172.16.10.202:8089'
    // 正式
    // BASE_URL = 'https://h.yltcloud.com'
    // 张建力
    BASE_URL = 'http://172.16.10.231:8000'
    // 王文帅
    // BASE_URL = 'http://172.16.10.183:8000'
    // BASE_URL = 'http://8055cu25ex09.vicp.fun'
} else if (process.env.NODE_ENV === 'testing') {
    BASE_URL = 'http://172.16.10.202:8089'
} else if (process.env.NODE_ENV === 'production') {
    BASE_URL = 'https://hw.forthehealth.org'
}
// npm run dev 测试运行
// npm run build 线上打包
// npm run test 打包测试环境
// npm run prod 线上运行

// console.log(BASE_URL)

//导出
export default BASE_URL
