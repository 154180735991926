import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//将原有的push方法地址，保存起来
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location, onResolved, onRejected) {
    if (onResolved === undefined && onRejected === undefined) {
        return originalPush.call(this, location).catch(() => { })
    } else {
        return originalPush.call(this, location, onResolved, onRejected)
    }
}

// 创建一个路由器，管理所有的路由
const routes = [
    {
        path: '/',
        component: resolve => require(['@/components/Login/Login'], resolve),
    },
    // 登录
    {
        path: '/login',
        name: 'login',
        component: resolve => require(['@/components/Login/Login'], resolve),
    },
    // 首页
    {
        path: '/index',
        name: 'index',
        component: resolve => require(['@/components/Index/Index'], resolve),
    },
    // 分会场页面
    {
        path: '/venue',
        name: 'venue',
        component: resolve => require(['@/components/Index/venue'], resolve),
    },
    //忘记密码
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        component: resolve => require(['@/components/password/forgetPassword.vue'], resolve),
    },
    //忘记密码 确认密码
    {
        path: '/confirmPwd',
        name: 'confirmPwd',
        component: resolve => require(['@/components/password/confirmPwd.vue'], resolve),
    },
    //注册
    {
        path: '/register',
        name: 'register',
        component: resolve => require(['@/components/register/register.vue'], resolve),
    },
    //注册
    {
        path: '/registerOk',
        name: 'registerOk',
        component: resolve => require(['@/components/register/success.vue'], resolve),
    },
    // 图片预览
    {
        path: '/showImg',
        name: 'showImg',
        component: resolve => require(['@/components/Index/showImg'], resolve)
    },
    {
        path: '/meet',
        name: 'meet',
        component: resolve => require(['@/components/Meet/meet'], resolve),
        children: [
            // 会议管理
            {
                path: '/manage',
                component: resolve => require(['@/components/Meet/manage.vue'], resolve),
                meta: {
                    activeMenu: '/manage', // 高亮
                }
            },
            {
                path: '/taskPro',
                component: resolve => require(['@/components/Meet/taskPro.vue'], resolve),
                meta: {
                    activeMenu: '/taskPro', // 高亮
                }
            },
            {
                path: '/wps',
                component: resolve => require(['@/components/Meet/wps.vue'], resolve),
                meta: {
                    activeMenu: '/wps', // 高亮
                }
            },
            // 工作人员管理
            {
                path: '/person',
                component: resolve => require(['@/components/Meet/person.vue'], resolve),
                meta: {
                    activeMenu: '/person', // 高亮
                }
            },
            // 参会人员管理
            {
                path: '/attend',
                component: resolve => require(['@/components/Meet/attend.vue'], resolve),
                meta: {
                    activeMenu: '/attend', // 高亮
                }
            },
            // 会议议程管理
            {
                path: '/agenda',
                component: resolve => require(['@/components/Meet/agenda.vue'], resolve),
                meta: {
                    activeMenu: '/agenda', // 高亮
                }
            },
            // 会议手册管理
            {
                path: '/manual',
                component: resolve => require(['@/components/Meet/manual.vue'], resolve),
                meta: {
                    activeMenu: '/manual', // 高亮
                }
            },
            // 会场座位管理
            {
                path: '/venueSeat',
                component: resolve => require(['@/components/Meet/venueSeat.vue'], resolve),
                meta: {
                    activeMenu: '/venueSeat', // 高亮
                }
            },
            // 用餐座位管理
            {
                path: '/repastSeat',
                component: resolve => require(['@/components/Meet/repastSeat.vue'], resolve),
                meta: {
                    activeMenu: '/repastSeat', // 高亮
                }
            },
            {
                path: '/temMan',
                component: resolve => require(['@/components/Meet/temMan.vue'], resolve),
                meta: {
                    activeMenu: '/temMan', // 高亮
                }
            },
            {
                path: '/signin',
                component: resolve => require(['@/components/Meet/signin.vue'], resolve),
                meta: {
                    activeMenu: '/signin', // 高亮
                }
            },
            {
                path: '/offSignIn',
                component: resolve => require(['@/components/Meet/offSignIn.vue'], resolve),
                meta: {
                    activeMenu: '/offSignIn', // 高亮
                }
            },
            // 会议资料管理
            {
                path: '/material',
                component: resolve => require(['@/components/Meet/material.vue'], resolve),
                meta: {
                    activeMenu: '/material', // 高亮
                }
            },
            {
                path: '/board',
                component: resolve => require(['@/components/Meet/board.vue'], resolve),
                meta: {
                    activeMenu: '/board', // 高亮
                }
            },
            // 待接受
            {
                path: '/toAccept',
                component: resolve => require(['@/components/Meet/toAccept.vue'], resolve),
                meta: {
                    activeMenu: '/toAccept', // 高亮
                }
            },
            // 进行中
            {
                path: '/toProgre',
                component: resolve => require(['@/components/Meet/toProgre.vue'], resolve),
                meta: {
                    activeMenu: '/toProgre', // 高亮
                }
            },
            // 待审核
            {
                path: '/toPenrev',
                component: resolve => require(['@/components/Meet/toPenrev.vue'], resolve),
                meta: {
                    activeMenu: '/toPenrev', // 高亮
                }
            },
            // 已完成
            {
                path: '/toComple',
                component: resolve => require(['@/components/Meet/toComple.vue'], resolve),
                meta: {
                    activeMenu: '/toComple', // 高亮
                }
            },
            // 新闻快讯通知
            {
                path: '/newsNotice',
                component: resolve => require(['@/components/Meet/newsNotice.vue'], resolve),
                meta: {
                    activeMenu: '/newsNotice', // 高亮
                }
            },
            // 开发中
            {
                path: '/driven',
                component: resolve => require(['@/components/Meet/Driven.vue'], resolve),
                meta: {
                    activeMenu: '/driven', // 高亮
                }
            },
            // 注册审核
            {
                path: '/registerAudit',
                component: resolve => require(['@/components/Meet/registerAudit.vue'], resolve),
                meta: {
                    activeMenu: '/registerAudit', // 高亮
                }
            },
            // 参会人角色管理
            {
                path: '/attendRole',
                component: resolve => require(['@/components/Meet/attendRole.vue'], resolve),
                meta: {
                    activeMenu: '/attendRole', // 高亮
                }
            },
            // 注册审核
            {
                path: '/attendRegisterAudit',
                component: resolve => require(['@/components/Meet/attendRegisterAudit.vue'], resolve),
                meta: {
                    activeMenu: '/attendRegisterAudit', // 高亮
                }
            },
            // 模板管理
            {
                path: '/moduleManage',
                component: resolve => require(['@/components/Meet/moduleManage.vue'], resolve),
                meta: {
                    activeMenu: '/moduleManage', // 高亮
                }
            },
            // 公告管理
            {
                path: '/noticeManage',
                component: resolve => require(['@/components/Meet/noticeManage.vue'], resolve),
                meta: {
                    activeMenu: '/noticeManage', // 高亮
                }
            }
        ]
    }
]

const router = new VueRouter({
    routes
})



// 暴露路由器
export default router
